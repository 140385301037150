
var options3 = {

    success: function (resp) {
        console.log("logger");
        let divElement2 = document.querySelector("#msg__container");
        divElement2.classList.add("show-elflex");

        $("#contact_name").val("");
        $("#contact_email").val("");
        $("#contact_msg").val("");
    },
    error: function (resp) {
        var errors = JSON.parse(resp.responseText);

        var keysErrors = Object.keys(errors);
        if (typeof errors == 'object') {
            for (var error of keysErrors) {
                var id = '#id_' + error;
                $(id).prepend(errors[error]);
                $(id).removeClass('hide-el')
            }
        } else {
            console.log(typeof errors, "");
        }
    }
};

$('#ajaxUtilityForm').ajaxForm(options3);